// Define constants for different elements
const desktopTopLevelMenuItems = document.querySelectorAll(
	'#header-desktop .menu-link-top.has-children'
);
const desktopSearchToggle = document.querySelector(
	'#header-desktop-search-toggle'
);
const desktopSearchWrapper = document.querySelector(
	'#header-desktop-search-wrapper'
);
const desktopSearchInput = document.querySelector(
	'#header-desktop-search-input'
);

// Function to close all submenus
const closeAllSubmenus = function () {
	// Loop through the submenus
	desktopTopLevelMenuItems.forEach( function ( menuItem ) {
		// Close the submenu
		menuItem.setAttribute( 'aria-expanded', 'false' );
	} );
};

// Function to close desktop search
const closeSearch = function ( event ) {
	// Remove the active classes on the toggle and the search form
	desktopSearchToggle.classList.remove( 'is-active' );
	desktopSearchWrapper.classList.remove( 'is-active' );
};

// Function to close submenus and search, then remove click event listener on body
const closeEverything = function ( event ) {
	// If it's a general close function (e.g. opening a menu
	// or it's a click from inside a menu item
	// or the search form is active and the click hasn't come from inside the search form
	if (
		event === undefined ||
		document
			.querySelector( '#desktop-menu-wrapper' )
			.contains( event.target ) ||
		( desktopSearchToggle.classList.contains( 'is-active' ) &&
			! document
				.querySelector( '#header-desktop-search-wrapper' )
				.contains( event.target ) )
	) {
		// Close any open submenus
		closeAllSubmenus();

		// Close search
		closeSearch();

		// Hide the overlay
		hideOverlay();

		// Remove the event listener
		document
			.querySelector( 'body' )
			.removeEventListener( 'click', closeEverything );
	}
};

// Add an event listener for a click on the search toggle
desktopSearchToggle.addEventListener( 'click', function ( event ) {
	// Stop propagation up to the body tag
	event.stopPropagation();

	// Close any open submenus
	closeAllSubmenus();

	// Toggle is-active classes on toggle and form
	desktopSearchToggle.classList.toggle( 'is-active' );
	desktopSearchWrapper.classList.toggle( 'is-active' );

	// If the form is now active
	if ( desktopSearchWrapper.classList.contains( 'is-active' ) ) {
		// Show the overlay
		showOverlay();

		// Set the aria-expanded attribute to true
		desktopSearchToggle.setAttribute( 'aria-expanded', 'true' );

		// Update the aria-label attribute
		desktopSearchToggle.setAttribute( 'aria-label', 'Deactivate search form' );

		// Focus the input
		desktopSearchInput.focus();

		// Add an event listener on the body for a click on anything else
		document
			.querySelector( 'body' )
			.addEventListener( 'click', closeEverything );

		// Otherwise it's deactivated
	} else {
		// Set the aria-expanded attribute to false
		desktopSearchToggle.setAttribute( 'aria-expanded', 'false' );

		// Update the aria-label attribute
		desktopSearchToggle.setAttribute( 'aria-label', 'Activate search form' );

		// Close the seach form
		closeEverything();
	}
} );

// Loop through the top-level menu items
desktopTopLevelMenuItems.forEach( function ( menuItem ) {
	// Add a click event listener
	menuItem.addEventListener( 'click', function ( event ) {
		// Store the clicked menu item
		let clickedMenuItem = event.target;

		// Stop the link being followed
		event.preventDefault();

		// Stop propagation up to the body tag
		event.stopPropagation();

		// Close the search
		closeSearch();

		// If this is opening a menu
		if ( clickedMenuItem.getAttribute( 'aria-expanded' ) === 'false' ) {
			// Show the overlay
			showOverlay();

			// Close the search form
			closeSearch();

			// Close any open submenus
			closeAllSubmenus();

			// Make aria-expanded true on clicked menu item
			clickedMenuItem.setAttribute( 'aria-expanded', 'true' );

			// Add an event listener on the body for a click on anything else
			document
				.querySelector( 'body' )
				.addEventListener( 'click', closeEverything );

			// Otherwise this menu needs to close
		} else {
			// Close any open submenus
			closeEverything();
		}
	} );
} );

// Listen for an escape key being pressed
document.addEventListener( 'keydown', function ( event ) {
	if ( event.key === 'Escape' ) {
		// Close anything that's open
		closeEverything();
	}
} );
