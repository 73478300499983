
// Global vars
var $biro_filters_form;

// On page load
jQuery( function ( $ ) {

	// Init
	$biro_filters_form = $( '.filters-form' );

	/* Toggle filters
	$biro_body.on( 'click', '.filters-toggle', function ( e ) {

		// Init
		var $el = $( this );

		if ( $el.attr( 'aria-expanded' ) === 'false' ) {
			$biro_filters_form.slideDown( 300, function () {
				$biro_filters_form.removeClass( 'filters-form-closed' );
			} );
			$el
				.attr( 'aria-expanded', 'true' )
				.find( '.label' )
				.text( 'Close filters' );
		} else {
			$biro_filters_form.slideUp( 300, function () {
				$biro_filters_form.addClass( 'filters-form-closed' );
			} );
			$el
				.attr( 'aria-expanded', 'false' )
				.blur()
				.find( '.label' )
				.text( 'Open filters' );
		}

	} );
	 */

	// Submit when filter is changed
	$biro_filters_form.on( 'change', 'select', function () {
		$biro_filters_form.submit();
	} );

	// Radio buttons
	$biro_body.on( 'click', '.filter-radio-buttons-item label', function ( e ) {

		// Init
		var $el = $( this );

		// Make sure input is checked
		$el.siblings( 'input[type="radio"]' ).prop( 'checked', true );

		// Submit form
		$el.parents( 'form' ).submit();

	} );

} );
