

// Global vars
var $biro_body;

// On page load
jQuery( function ( $ ) {

	// Global vars
	$biro_body = $( 'body' );

});