// Define constants for different elements
const mobileTopLevelMenuItems = document.querySelectorAll(
	'#header-mobile .menu-link-top.has-children'
);
const mobileMenuToggle = document.querySelector( '#mobile-menu-toggle' );
const mobileMenu = document.querySelector( '#mobile-menu' );

// Listen for a click on the menu toggle
mobileMenuToggle.addEventListener( 'click', function ( event ) {
	// If the aria-expanded attribute is false
	if ( mobileMenuToggle.getAttribute( 'aria-expanded' ) === 'false' ) {
		// Open the menu
		mobileMenu.classList.add( 'is-active' );

		// Update the aria-expanded attribute on the toggle
		mobileMenuToggle.setAttribute( 'aria-expanded', 'true' );

		// Otherwise the aria-expanded attribute is true
	} else {
		// Close the menu
		mobileMenu.classList.remove( 'is-active' );

		// Update the aria-expanded attribute on the toggle
		mobileMenuToggle.setAttribute( 'aria-expanded', 'false' );
	}
} );

// Listen for an escape key being pressed
document.addEventListener( 'keydown', function ( event ) {
	if ( event.key === 'Escape' ) {
		// Close the menu
		mobileMenu.classList.remove( 'is-active' );

		// Update the aria-expanded attribute on the toggle
		mobileMenuToggle.setAttribute( 'aria-expanded', 'false' );

		// Blur the toggle
		mobileMenuToggle.blur();
	}
} );

// Loop through the top-level menu items
mobileTopLevelMenuItems.forEach( function ( menuItem ) {
	// Add a click event listener
	menuItem.addEventListener( 'click', function ( event ) {
		// Stop the link being followed
		event.preventDefault();

		// Stop propagation up to the body tag
		event.stopPropagation();

		// If this is opening a menu
		if ( event.target.getAttribute( 'aria-expanded' ) === 'false' ) {
			// Make aria-expanded true on clicked menu item
			event.target.setAttribute( 'aria-expanded', 'true' );

			// Otherwise this menu needs to close
		} else {
			// Make aria-expanded false on clicked menu item
			event.target.setAttribute( 'aria-expanded', 'false' );
		}
	} );
} );
