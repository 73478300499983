/**
 * Google Tag Manager functionality
 *
 * Any vars that need immediately passing to the data layer on page load
 * should be passed from PHP via the hooked biro_gtm_gtm4wp_compile_datalayer()
 * function in gtm.php.
 */

// Run when DOM is ready
jQuery( function ( $ ) {
	var $gfc = $( '.gform_confirmation_wrapper' );

	// Fire confirmation for non-AJAX Gravity Forms
	if ( $gfc.length ) {
		biro_gtm_data_layer_push( {
			event: 'gform_confirmation_loaded',
			formID: 'gform_' + $gfc.attr( 'id' ).split( '_' ).pop(),
		} );
	}
} );

// Gravity Forms confirmation event
// Needs passing through to GTM data layer
jQuery( document ).bind(
	'gform_confirmation_loaded',
	function ( event, formID ) {
		biro_gtm_data_layer_push( {
			event: 'gform_confirmation_loaded',
			formID: 'gform_' + formID,
		} );
	}
);

/* This seems to fire on AJAX forms even when there's no error - back to tracking success for now
// Gravity Forms post render event, to catch validation errors
jQuery( document ).bind( 'gform_post_render', function( event, formID ) {
	var $f = $( '#gform_' + formID );
	if ( $f.find( '.validation_error' ).length ) {
		biro_gtm_data_layer_push({
			'event':	'gform_error',
			'formID':	'gform_' + formID
		});
	}
});
*/

/**
 * Safe wrapper for pushing to dataLayer
 *
 * @param	{object}	v		Variables
 * @return	void
 */
function biro_gtm_data_layer_push( v ) {
	if ( typeof dataLayer != 'undefined' ) {
		dataLayer.push( v );
	}
}

/**
 * Get from dataLayer
 *
 * @param	{string}	v		Var name
 * @return	mixed
 */
function biro_gtm_data_layer_get( v ) {
	var val = null;

	if ( typeof dataLayer != 'undefined' ) {
		val = dataLayer[ v ];
	}

	return val;
}
