// Function to close privacy notice
function privacyNoticeClose( decision ) {
	// Set a cookie with the decision that lasts 31536000 seconds (one year)
	document.cookie =
		'privacy-cookies=' +
		decision +
		'; ' +
		'max-age=' +
		'31536000' +
		'; ' +
		'path=' +
		'/' +
		'; ' +
		'domain=' +
		window.location.hostname +
		'; ' +
		'SameSite=' +
		'Strict' +
		'; ' +
		'Secure';

	// Remove the privacy notice from the DOM tree
	document.getElementById( 'biro-privacy-notice' ).remove();
}

// Attempt to get an existing cookie
let privacyCookieResult = document.cookie.match(
	'(^|[^;]+)\\s*privacy-cookies\\s*=\\s*([^;]+)'
);

// Declare an existing privacy decision if it exists, otherwise mark it as undefined
let privacyDecision = privacyCookieResult
	? privacyCookieResult.pop()
	: 'undefined';

// If all these are true:
if (
	privacyDecision === 'undefined' && // There's no existing privacy decision
	navigator.doNotTrack !== '1' && // Do Not Track is not set (most browsers)
	window.doNotTrack !== '1' // Do Not Track is not set (some versions of IE / Edge)
) {
	// Create the privacyNotice div
	let privacyNotice = document.createElement( 'div' );

	// Add the privacy-notice class
	privacyNotice.setAttribute( 'id', 'biro-privacy-notice' );

	// Add the privacy-notice class
	privacyNotice.setAttribute( 'class', 'biro-privacy-notice' );

	// Add the content
	privacyNotice.innerHTML =
		'<div class="container"><div class="row"><div class="col-lg-5"><h2 class="heading">Website cookies</h2></div><div class="col-lg-10"><div class="text">This website uses cookies to help us understand the way visitors use it. We can\'t identify you with them and we don\'t share the data with anyone else. If you click Reject we will set a single cookie to remember your preference. Find out more in our <a href="' +
		biro_privacy_notice.privacy_policy_permalink +
		'">privacy policy</a>.</div><div class="buttons"><button id="biro-privacy-accept" class="biro-button accept">Accept cookies</button><button id="biro-privacy-reject" class="biro-button biro-button-outline reject">Reject<span> cookies</span></button></div></div></div></div>';

	// Insert the privacy notice
	document.body.insertBefore( privacyNotice, document.body.firstChild );

	// Add an event listener for when the accept button is clicked
	document
		.querySelector( '#biro-privacy-accept' )
		.addEventListener( 'click', function () {
			// Close the privacy notice and note the acceptance
			privacyNoticeClose( 'accept' );
		} );

	// Add an event listener for when the reject button is clicked
	document
		.querySelector( '#biro-privacy-reject' )
		.addEventListener( 'click', function () {
			// Close the privacy notice and note the rejection
			privacyNoticeClose( 'reject' );
		} );
}
